body,
html {
  background: #fefefe;
}

.app {
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 1rem;
}

.app > h1 {
  font-size: 2rem;
  margin-bottom: 3rem;
}
